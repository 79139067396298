import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, cancelButton, confirmButton, changeOnSelect, iconColorSelect } from '../campaign/Campain.css';
import { Grid, InputAdornment, TextField, Chip, Popover, Modal } from '@material-ui/core';
import Icon from '../IconsComponent';
import _ from 'lodash';

export const FilterListComponent = ({ planningType, setAnchor, setPlanningType, filterValue, filterCondition, chipText, name, planningTypeSelected, icon, multiChip }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  let filters = useSelector(ContentSelectors.didGetFilter)

  let offsetGrid: any = document.getElementById(filterValue + "name")?.offsetHeight

  const deleteFromArray = (array: any, filterKey: string) => {
    let newArray = [...array]
    const keyToDelete = array[0]

    dispatch(ContentActions.willUploadFilter({
      key: filterKey, value: newArray.filter((i: string) => {
        return i !== keyToDelete
      })
    }))
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
  }

  const totalSelected = (array: any, filterKey: string) => {
    return (
      <>
        {array.length > 1
          ? <div className="ml-3">
            <Chip
              data-cy="chip"
              variant="outlined"
              label={array[0]}
              onDelete={() => { deleteFromArray(array, filterKey) }} />
            <span className="detailText"> e altri {array.length - 1}</span>
          </div>
          : <Chip
            className="ml-3"
            data-cy="chip"
            variant="outlined"
            label={array[0]}
            onDelete={() => { deleteFromArray(array, filterKey) }} />
        }
      </>
    )
  }

  return (
    <>
      <Grid data-cy={filterValue} id={filterValue + "Grid"} className={`hoverPointer`} container item xs={12} alignItems="center"
        onClick={(event: any) => { setAnchor(); setPlanningType(planningType) }}>
        <Grid className="d-sm-flex" item xs={1}>
          <div className={changeOnSelect(planningTypeSelected, planningType)} style={{ height: planningType === planningTypeSelected ? (offsetGrid + 15) : 0 }} />
        </Grid>
        <Grid className="d-sm-flex" item xs={1}>
          <Icon color={iconColorSelect(planningTypeSelected, planningType)} size={20} icon={icon} />
        </Grid>
        <Grid id={filterValue + "name"} style={{ color: planningType === planningTypeSelected ? '#0062cc' : 'black' }} data-cy={planningType} item xs={10}><span>{name}</span></Grid>
      </Grid>
      {filterCondition ?
        multiChip
          ? totalSelected(filters[filterValue], filterValue)
          : <Chip
            className="ml-4"
            data-cy={filterValue + "Chip"}
            variant="outlined"
            label={chipText}
            onDelete={() => {
              dispatch(ContentActions.willUploadFilter({ key: filterValue, value: [] }))
              if (filters.billboard_class && (filters.media_type && filters.media_type.length === 1)) {
                dispatch(ContentActions.willUploadFilter({ key: 'billboard_class', value: [] }))
              }
              dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
            }} /> : null
      }

    </ >
  )
}