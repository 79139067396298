import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { Button, ButtonGroup, Popover, Typography, Grid, Paper, Tooltip } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useStyles, modifiersStyles, renderDay } from '../AvailabilityComponent.css';
import Icon from '../IconsComponent';
import moment from 'moment';
import 'moment/locale/it';
import _ from 'lodash';


export const AvailabilityComponent = (props: any) => {
  const { setDate, setFilter, filter, setStartBusyDates } = props
  const classes = useStyles();
  let filters = useSelector(ContentSelectors.didGetFilter)
  //const data = props.item
  const [year, setYear] = useState(moment()) as any
  const [period, setPeriod] = useState({} as any)
  const [currentYear, setCurrentYear] = useState(moment().year())
  let occupatedDays1 = [] as any
  const [occupatedDays, setOccupatedDays] = useState(filters.period ? filters.period.uscite : occupatedDays1) as any
  const [selectedQuatt, setSelectedQuatt] = useState([]) as any
  const [selectedDates, setSelectedDates] = useState([]) as any

  // console.log("filter", filter)


  let startYear: any
  if (currentYear === 2023) {
    startYear = moment("2023-01-02")
  }
  else if (currentYear === 2034) {
    startYear = moment("2034-01-02")
  }
  else if (currentYear === 2040) {
    startYear = moment("2040-01-02")
  }
  else if (currentYear === 2045) {
    startYear = moment("2045-01-02")
  } else {
    startYear = moment().year(currentYear).startOf('year').day("Monday").add(8, 'days')
  }

  const select = (quattordicina: any) => {
    let quatt = [...selectedQuatt]
    if (quatt.includes(quattordicina)) {
      setSelectedQuatt(quatt.filter((i: any) => {
        return i != quattordicina
      }))
    }
    else {
      quatt.push(quattordicina)
      setSelectedQuatt(quatt)
    }
  }

  const selectDates = (quattordicina: any) => {
    let date = startYear.add(_.multiply(7, quattordicina - 1), 'days').format('YYYY-MM-DD')
    let dates = [...selectedDates]
    // console.log("selected includes ", date, dates, dates.includes(date))
    // (((array.includes(numeroUscita - 1) || array.includes(numeroUscita + 1)) || array.length === 0) && array.length !== 6)
    if (dates.includes(date)) {
      // console.log("selected includes if", date, dates, dates.includes(date))
      setSelectedDates(dates.filter((i: any) => {
        return i != date
      }))
    }
    else if (((occupatedDays.includes(quattordicina - 1) || occupatedDays.includes(quattordicina + 1)) || occupatedDays.length === 0) && (occupatedDays.length !== 6)) {
      dates.push(date)
      setSelectedDates(dates)
    }
  }
  // console.log("selectDates", selectedDates)

  useEffect(() => {

    if (filters.period) {
      let startYearFilter = moment().year(filters.period.year).startOf('year').day("Monday").add(8, 'days')
      let dates = [...selectedDates]
      _.forEach(filters.period.uscite, (o: any) => {
        let date = startYearFilter.clone()
        date.add(_.multiply(7, o - 1), 'days')
        dates.push(date.format('YYYY-MM-DD'))
      })
      setSelectedDates(dates)
    }

  }, [])
  // console.log("selectedDays", selectedDates)

  const VisualizedDate = (quattordicina: any) => {
    let startYear: any
    if (currentYear === 2023) {
      startYear = moment("2023-01-02")
    }
    else if (currentYear === 2034) {
      startYear = moment("2034-01-02")
    }
    else if (currentYear === 2040) {
      startYear = moment("2040-01-02")
    }
    else if (currentYear === 2045) {
      startYear = moment("2045-01-02")
    } else {
      startYear = moment().year(currentYear).startOf('year').day("Monday").add(8, 'days')
    }
    return <span data-cy="buttonNotOccupated" style={{ fontSize: 15, color: "black" }} >
      {startYear.add(_.multiply(7, quattordicina - 1), 'days').format('DD-MM-YYYY')}
    </span>
  }

  const addDateToReserve = (quattordicina: any) => {
    let annoAttuale = !_.isEmpty(filter) ? filter.year : moment().year()
    let numeroUscita = (currentYear - annoAttuale) * 52 + quattordicina
    // console.log("currentYear",annoAttuale)
    let date = {} as any
    console.log("period", period, filters)

    let array = [...occupatedDays]
    const condition = ((array.includes(numeroUscita - 1) || array.includes(numeroUscita + 1)) || array.length === 0) && (array.length !== 6)
    console.log("condition", condition)
    let saltaCondizioniSeEliminoOSonoUnaUscitaCentrale = false
    if (array.includes(numeroUscita)) {
      if (!array.includes(numeroUscita - 1) || !array.includes(numeroUscita + 1)) {
        // console.log('in true', occupatedDays, numeroUscita)
        let max = _.max(array)
        if (max === numeroUscita) {
          date = {
            from: period.from,
            to: moment(startYear).add(_.multiply(7, quattordicina - 2) + 7, 'days').format("DD-MM-YYYY")
          }
        } else {
          date = {
            from: moment(startYear).add(_.multiply(7, quattordicina), 'days').format("DD-MM-YYYY"),
            to: period.to
          }
        }
        array.splice(array.indexOf(numeroUscita), 1)
        saltaCondizioniSeEliminoOSonoUnaUscitaCentrale = true
        console.log("elimino date?", date, max, numeroUscita, moment(startYear).add(_.multiply(7, quattordicina - 2) + 7, 'days').format("DD-MM-YYYY"))
      } else {
        date = {
          from: period.from,
          to: period.to
        }
        saltaCondizioniSeEliminoOSonoUnaUscitaCentrale = true   // se clicco un'uscita che è in mezzo ad altre
      }
    } else if (condition) {
      array.push(numeroUscita)
      // console.log('else', occupatedDays)
    }

    // console.log("occupatedDays", occupatedDays)
    if (array.length > 0) {
      if (!saltaCondizioniSeEliminoOSonoUnaUscitaCentrale) {
        console.log('occupatedDays array non vuoto', array)
        let max = _.max(array)
        let min = _.min(array)
        console.log("massimo e minimo uscite", max, min)
        if (typeof (min) === "number" && typeof (max) === "number") {
          let anno_from: number = min <= 26 ? annoAttuale : annoAttuale + _.ceil(_.divide(min, 26)) - 1
          let anno_to: number = max <= 26 ? annoAttuale : annoAttuale + _.ceil(_.divide(max, 26)) - 1
          // console.log('data minimo massimo', min, max, anno_from, anno_to)
          // let startYear = moment(year).startOf('year').day("Monday").add(8, 'days')

          console.log("controlli", array[0], max, min, numeroUscita)
          if (array.length === 1 && condition) {
            // console.log("else if true")
            date = {
              from: moment(startYear).add(_.multiply(7, quattordicina - 1), 'days').format("DD-MM-YYYY"),
              to: moment(startYear).add(_.multiply(7, quattordicina - 1) + 7, 'days').format("DD-MM-YYYY")
            }
            console.log("date post calcolo 1", date)
          } else if (array[0] < numeroUscita && condition) {
            // console.log("else if", array[0] < quattordicina)
            date = {
              from: period.from,
              to: moment(startYear).add(_.multiply(7, quattordicina - 1) + 7, 'days').format("DD-MM-YYYY")
            }
            console.log("date post calcolo 2", date)
          } else {
            if (condition) {
              date = {
                from: moment(startYear).add(_.multiply(7, quattordicina - 1), 'days').format("DD-MM-YYYY"),
                to: period.to
              }
              console.log("date post calcolo 3", date)
            } else {
              date = {
                from: period.from,
                to: period.to
              }
            }
          }
        }
      }

      setPeriod(date)

    } else {
      setPeriod({})
    }
    setOccupatedDays(array)
  }

  useEffect(() => {
    setDate(period)
    console.log('uscite useeffect: ', period, filters, selectedDates)

    if (_.isEmpty(period)) {
      // setFilter({})
      setFilter(filters.period)
      if (filters.period) {
        setPeriod(filters.period.date)
      }
    } else {

      setFilter({ year: moment().year(), uscite: occupatedDays, date: period })
      setStartBusyDates(selectedDates)
    }
  }, [year, selectedQuatt, currentYear, occupatedDays])

  const array = _.range(1, 53)

  return (
    <div className="d-sm-flex flex-column align-items-center mr-4 detailText">
      <div className="d-sm-flex align-items-center ml-3">
        <Button disabled={currentYear === moment().year() ? true : false} data-cy="prevYear" style={{ fontSize: 12 }} className="border-0" onClick={() => { setCurrentYear(currentYear - 1); setYear(moment(year).subtract(1, 'year')) }}><Icon size={20} color="#bfbfbf" icon="chevronLeft" /></Button>
        <b>{year.year()}</b>
        <Button data-cy="nextYear" style={{ fontSize: 12 }} className="border-0" onClick={() => { setCurrentYear(currentYear + 1); setYear(moment(year).add(1, 'year')) }}><Icon className="right" size={20} color="#bfbfbf" icon="chevronRight" /></Button>
      </div>
      <div className="d-sm-flex justify-content-around mt-2">
        <Grid container item xs={12} spacing={3}>
          {array.map((i: number) => {
            // let isDotOccupated = _.forEach(occupatedDays, function (number: number) { return number === (_.multiply(i, currentYear - moment().year())) ? true : false })
            let isOccupated = true
            _.forEach(occupatedDays, function (value: number) {
              if (value === (_.multiply(26, (currentYear - moment().year())) + i)) {
                isOccupated = false
              }
            })
            let isAfter = false
            const startYear = moment(year).startOf('year').day("Monday").add(8, 'days')
            let date = startYear.add(_.multiply(7, i - 1), 'days').format('YYYY-MM-DD')
            let today = moment().add(60, 'days').format('YYYY-MM-DD')
            if (moment(today).isAfter(date)) {
              isAfter = true
            }
            return (
              <Grid key={`uscita-${i}`} item xs={2}>
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <>
                      <Tooltip disableFocusListener disableTouchListener title={isAfter ? <span style={{ fontSize: 15, color: "black" }}>Non selezionabile</span> : <span>{VisualizedDate(i)}</span>}>
                        <button data-cy={`quattordicina-${i}`} className={isAfter ? classes.buttonUnclickable : isOccupated ? classes.buttonNotOccupated : classes.buttonOccupated} color="primary" {...bindTrigger(popupState)}
                          onClick={() => {
                            if (!isAfter) {
                              select(i);
                              addDateToReserve(i)
                              selectDates(i)
                            }
                          }}><span className="d-sm-flex flex-column align-items-center"><a>{i}</a><div className={isOccupated ? classes.dotNotOccupated : classes.dotOccupated} /></span></button>
                      </Tooltip>
                      <Popover data-cy={`popoverOccupated-${i}`} {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                      >
                        {/* {isOccupated} */}
                        {/* {occupatedDays.includes(i) ? occupatedPopover(i) : NotOccupatedPopover(i)} */}
                      </Popover>
                    </>
                  )}
                </PopupState>
              </Grid>
            )
          })
          }
        </Grid>
      </div>
    </div>
  );
}

// ((occupatedDays.includes(i - 1) || occupatedDays.includes(i + 1)) || occupatedDays.length === 0) && (occupatedDays.length !== 6)