import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content'
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../campaign/Campain.css';
import { Grid, Chip } from '@material-ui/core';
import Icon from '../IconsComponent';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../Suggestion.hooks";
import _ from 'lodash';


export const Municipality = (props: any) => {
  const { setAnchor, setPlanningType } = props
  let filters = useSelector(ContentSelectors.didGetFilter)
  const classes = useStyles();
  const dispatch = useDispatch()
  const [suggestions, setSuggestions] = useState([] as any)
  const [selectedCity, setSelectedCity] = useState(filters.city ? filters.city : [] as any)
  //// questi sotto servono per AutoSuggest, non eliminateli!
  const [data, setData] = useState("")
  const [fieldValue, setFieldValue] = useState("")
  const [city, setCity] = useState("")

  const deleteCity = (city: any) => {
    let cities = [...selectedCity]
    setSelectedCity(cities.filter((i: any) => {
      return i != city
    }))
  }

  const pushCity = (city: any) => {
    let cities = [...selectedCity]
    cities.push(city.name)
    setSelectedCity(cities)
  }

  const {
    getSuggestions,
    getSuggestionValue,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    setValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  }: any = useSuggestion({
    setFieldValue,
    setData,
    setSuggestions,
    setCity,
    pushCity,
  })

  const inputProps: any = {
    placeholder: "Comune",
    value,
    onChange: onChange,
    className: "autosuggestFilter px-3",
    id: "comune",
  };

  const applyFunction = () => {
    dispatch(ContentActions.willUploadFilter({ key: "city", value: selectedCity }))
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
    setAnchor(null)
    setPlanningType("")
  }

  useEffect(() => {
    console.log('modifying selectedCity in Municipality', selectedCity)
  }, [selectedCity])

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid item xs={12}><b>Località</b></Grid>
        <Grid container item xs={12}>
          <Grid className="mb-2" item xs={12}><span className="text-secondary detailText">CERCA COMUNE</span></Grid>
          <Grid item xs={12} className="mx-1">
            <Autosuggest
              multiSection={true}
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={inputProps}
              theme={{
                input: classes.autosuggest,
                inputOpen: classes.autosuggest,
                inputFocused: classes.inputFocused,
                suggestionsContainer: classes.absolute,
                suggestionsContainerOpen: classes.absoluteOpen,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {selectedCity ? selectedCity.map((city: any, index: any) => {
            return (
              <Chip
                data-cy="cityChip"
                variant="outlined"
                label={city}
                key={index}
                onDelete={() => deleteCity(city)} />
            )
          }) : null}
        </Grid>
        <Grid className={classes.buttonsFilterContainer} container>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button
              style={cancelButtonFilters}
              data-cy="goBack"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >Annulla</button>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}