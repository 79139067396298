import React from "react";
import "./App.scss";

import { Provider } from "react-redux";
import { configureStore } from "./store";
import { AppRouter } from "./router";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Provider as URQLProvider } from "urql";
import urqlClient from "./graphql/client";
import Layout from "layout/layout";
Sentry.init({
  dsn: "https://a731f9a386d94ac8a6df08d0e200771e@logs.radixcloud.org/9",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export default function App() {
  return (
    <URQLProvider value={urqlClient}>
      <Layout>
        <Provider store={configureStore({})}>
          <AppRouter />
        </Provider>
      </Layout>
    </URQLProvider>
  );
}
