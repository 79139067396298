import { Container, Form } from "reactstrap";
import { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as ProfileActions,
  selectors as ProfileSelectors,
} from "store/slices/profile";
import {
  actions as UiActions,
  selectors as UiSelectors,
} from "store/slices/ui";
import placeholder from "assets/images/placeholder_profile.png";
import Icon from "components/IconsComponent";
import moment from "moment";

import { Spinner } from "reactstrap";
import { selectors as UISelectors } from "../store/slices/ui";
import { TextField } from "@material-ui/core";
import { Advertiser, AdvertiserInput } from "graphql/generated";

const profileState = {
  advertiser: "",
  business_name: "",
  name: "",
  type: "",
  p_iva: "",
  company_form: "",
  company_type: "",
  fiscal_code: "",
  pec: "",
  email: "",
  phone: "",
  legal_municipality: "",
  legal_address: "",
  legal_cap: "",
  firstJob: null,
  lastYearCampaign: null,
  founded_by: "",
  image: "",
  subscription_date: moment().format("YYYY-MM-DD"),
  legal_email: "",
  bank_account: "",
  society_form: "",
  recipient_code: "",
} as Advertiser;

/* A simple div which can transform to an input depending on an external state */
const MutableField = ({
  /** The key to set on the state provided by the setter */
  stateKey,
  /** defines if this component should switch into an "input" state */
  switchInput,
  label = "",
  setter = (state: any) => state,
  value,
  placeholder = "-",
  type="text",
  autoComplete="on"
}) => {
  const MAX_STRING_LENGTH = 20;
  // Coercion to string
  let val = (value || placeholder) + "";
  // Shorten long strings
  if(val.length> MAX_STRING_LENGTH){
    val = val.trim().slice(0,MAX_STRING_LENGTH-3)+"..."
  }
  if (switchInput) {
    return (
      <TextField
        size="small"
        id={stateKey}
        label={label}
        onChange={(e) => setter({ [stateKey]: e.target.value })}
        defaultValue={value}
        type={type}
        autoComplete={autoComplete}
      />
    );
  }
  return <span>{val}</span>;
};

export const Profile = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useReducer(
    (state: typeof profileState, newState: typeof profileState) => ({
      ...state,
      ...newState,
    }),
    profileState
  );
  const advertiser = useSelector(ProfileSelectors.getAdvertiser);
  const [editProfile, setEditProfile] = useState(false);
  // Registry = "Anagrafica"
  const [editRegistry, setEditRegistry] = useState(false);
  const [editAccount, setEditAccount] = useState(false);

  const isApplyingChanges = useSelector((state) =>
    UISelectors.activityRunningSelector(state, "willAddAdvertiser")
  );

  /** Edit/Apply button */
  const EditApply = ({ state, setter }) => {
    return (
      <button
        className="border rounded bg-transparent text-muted"
        style={{ height: "fit-content" }}
        disabled={isApplyingChanges}
        onClick={() => {
          if (state) {
            // Removes the "advertiser" field on the input for addAdvertiser
            const {advertiser:_,...newProfile} = profile;
            dispatch(ProfileActions.willAddAdvertiser(newProfile as AdvertiserInput));
          }
          setter(!state);
        }}
      >
        <Icon size={25} icon={state ? "check" : "edit"} />
        {state ? "Applica" : "Modifica"}
      </button>
    );
  };
  useEffect(() => {
    if (advertiser) {
      setProfile(advertiser);
    }

    dispatch(UiActions.setSelectedPage(""));
  }, [advertiser, dispatch]);

  return (
    <div style={{ backgroundColor: "#fafafb" }} className="w-100 h-100">
      <Container className="d-flex flex-row poppinsFont pt-3 col-8">
        {/* Main content */}
        <Container className="d-flex flex-column gap-3">
          {/* Base/Main Info */}
          <div className="d-flex flex-row w-100  bg-white p-3 rounded-xl mt-5">
            <img
              alt="Business logo"
              className="rounded-xl"
              style={{
                transform: "scale(1.7) translateX(25%) translateY(-10%)",
                marginRight: "100px",
                border: "4px solid #fafafb",
              }}
              src={placeholder}
              width="100"
              height="100"
            />
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between mb-5">
                <h3>
                  <b className="mr-3">
                    <MutableField
                      setter={setProfile}
                      stateKey="business_name"
                      switchInput={editProfile}
                      label="Ragione Sociale"
                      value={
                        editProfile
                          ? profile.business_name
                          : profile.business_name &&
                            profile.business_name.replaceAll("_", " ")
                      }
                      autoComplete={"organization"}
                    />
                  </b>
                </h3>
                <EditApply state={editProfile} setter={setEditProfile} />
              </div>

              <div className="grid-2 gap-1">
                <div className="grid-2 grid-gap-1">
                  <div className="text-muted">Spazi venduti</div>
                  <div>xx</div>
                  <div className="text-muted">Iscritto dal</div>
                  <div>
                    <i>
                      {()=>{
                        console.log(profile.subscription_date)
                        return moment(profile.subscription_date).format("DD-MM-YYYY");
                      }}
                    </i>
                  </div>
                </div>
                <div className="grid-2 grid-gap-1">
                  <div className="text-muted">Piano di abbonamento</div>
                  <div>BASE</div>
                  <div className="text-muted">Livello Fee</div>
                  <div>xx</div>
                </div>
              </div>
            </div>
          </div>
          {/* Dati fatturazione */}
          <div className="d-flex flex-column w-100  bg-white p-3 rounded-xl mt-5">
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <h6>
                  <b className="mr-3">Dati fatturazione</b>
                </h6>
                <EditApply state={editRegistry} setter={setEditRegistry} />
              </div>
            </div>
            <div className="grid-4 grid-gap-3 w-100">
              <div>
                <span className="text-muted">P.IVA</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="p_iva"
                  switchInput={editRegistry}
                  value={profile.p_iva}
                />
              </div>
              <div>
                <span className="text-muted">Sede legale</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="legal_address"
                  switchInput={editRegistry}
                  value={profile.legal_address}
                  autoComplete={"street-address"}
                />
              </div>
              <div>
                <span className="text-muted">Codice destinatario</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="recipient_code"
                  switchInput={editRegistry}
                  value={profile.recipient_code}
                />
              </div>
              <div>
                <span className="text-muted">Email</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="email"
                  switchInput={editRegistry}
                  value={profile.email}
                  type="email"
                  autoComplete={"email"}
                />
              </div>
              <div>
                <span className="text-muted">Codice fiscale</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="fiscal_code"
                  switchInput={editRegistry}
                  value={profile.fiscal_code}
                />
              </div>
              <div>
                <span className="text-muted">PEC</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="legal_email"
                  switchInput={editRegistry}
                  value={profile.legal_email}
                />
              </div>
              <div>
                <span className="text-muted">Forma societaria</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="society_form"
                  switchInput={editRegistry}
                  value={profile.society_form}
                />
              </div>
              <div>
                <span className="text-muted">Telefono</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="phone"
                  switchInput={editRegistry}
                  value={profile.phone}
                  type="tel"
                  autoComplete={"tel"}
                />
              </div>
            </div>
          </div>
          {/* Dati Account */}
          <div className="d-flex flex-column w-100  bg-white p-3 rounded-xl mt-5">
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <h6>
                  <b className="mr-3">Dati account</b>
                </h6>
                <EditApply state={editAccount} setter={setEditAccount} />
              </div>
            </div>
            <div className="grid-4 grid-gap-3 w-100">
              <div>
                <span className="text-muted">Nome referente</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="name"
                  switchInput={editAccount}
                  value={profile.name}
                  autoComplete={"name"}
                />
              </div>
              <div>
                <span className="text-muted">Email</span>
                <br />
                <MutableField
                  setter={setProfile}
                  stateKey="email"
                  switchInput={editAccount}
                  value={profile.email}
                  type="email"
                  autoComplete={"email"}
                />
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};
