import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../campaign/Campain.css';
import { Grid } from '@material-ui/core';
import Icon from '../IconsComponent';
import { AvailabilityComponent } from './AvailabilityComponent';
import _ from 'lodash';


export const Period = (props: any) => {
  const { setAnchor, setPlanningType } = props
  const classes = useStyles();
  const dispatch = useDispatch()

  let filters = useSelector(ContentSelectors.didGetFilter)

  const [startDate, setStartDate] = useState(null)
  const [dateFrom, setDateFrom] = useState(filters.period ? filters.period.from : "")
  const [endDate, setEndDate] = useState(null)
  const [dateTo, setDateTo] = useState(filters.period ? filters.period.to : "")
  const [date, setDate] = useState() as any
  const [filter, setFilter] = useState({}) as any
  const [startBusyDates, setStartBusyDates] = useState({}) as any
  const [uscite, setUscite] = useState([]) as any

  const applyFunction = () => {
    dispatch(ContentActions.willUploadFilter({ key: "period", value: { ...filter } }))
    dispatch(ContentActions.willFilterBillboards({ filters: {} }))
    setAnchor(null)
    setPlanningType("")
  }

  useEffect(() => {
    if (filters.period) {
      setStartDate(filters.period.from)
      setEndDate(filters.period.to)
      setFilter(filters.period)
    }
    console.log('da filters::', filter, date)
    console.log(filters)
  }, [filters])

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <b>Calendario settimane dell'anno</b>
          <br />
          <p style={{ fontSize: 12 }} className="text-muted">Seleziona il periodo cliccando sui numeri. I numeri rappresentano le settimane in un anno</p>
        </Grid>
        <Grid style={{ marginTop: -30, fontSize: 15 }} item xs={12}>
          {!_.isEmpty(filter) ? <>
            <p>Periodo scelto: da {filter.date.from} a {filter.date.to}</p>
            <p>Uscite selezionate: {filter.uscite.toString()}</p>
          </> : null}
        </Grid>
        <Grid data-cy="period-component" className={!_.isEmpty(filter) ? classes.filterBodyPeriod : classes.filterBody} container item xs={12}>
          <AvailabilityComponent setDate={setDate} setFilter={setFilter} filter={filter} setStartBusyDates={setStartBusyDates} />
        </Grid>
        <Grid className={classes.buttonsFilterContainer} container>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button
              style={cancelButtonFilters}
              data-cy="goBack"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >Annulla</button>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}