import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { actions as ProfileActions } from '../slices/profile'
import { actions as AuthActions } from '../slices/auth'
import { actions as UiActions, selectors as UiSelectors } from '../slices/ui';
import { actions as NotificationActions } from '../slices/notification'
import * as AuthApi from '../../api/auth'
import _ from 'lodash';

export function* sagas() {
  console.log('inside profile saga')
  yield takeLatest(ProfileActions.willRetrieveProfileData.type, willRetrieveProfileData)
  yield takeLatest(AuthActions.didLoginUserSuccess.type, willRetrieveProfileData)
  yield takeLatest(ProfileActions.willAddAdvertiser.type, willAddAdvertiser)
  yield takeLatest(ProfileActions.willGetAdvertiser.type, willGetAdvertiser)

}

// function* willRetrieveProfileData(action: any) {
//   console.log('in willGetProfileData function');
//   try {
//     const user = yield call(AuthApi.getAuthenticatedUser);
//     console.log('with user in willRetrieveProfileData: ', user)
//     yield put(ProfileActions.didRetrieveProfileData(user.attributes))
//   } catch (error: any) {
//     console.log('Error retriving profile data', error);
//     yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
//   }

// }

function* willRetrieveProfileData(action: any) {
  console.log('in willGetProfileData function');
  try {
    const user = yield call(AuthApi.getAuthenticatedUser);
    // let advertiser = {} as any
    // console.log('with user in willRetrieveProfileData: ', user, !user.signInUserSession.accessToken.payload["cognito:groups"])
    // yield put(ProfileActions.didRetrieveProfileData(user))

    if (!user.signInUserSession.accessToken.payload["cognito:groups"]) { /// se l'utente non appartiene a nessun gruppo
      // yield put(ProfileActions.didRetrieveProfileData(user.attributes))
      localStorage.setItem('usernameNewGroup', user.username)
      yield put(UiActions.didSetNoGroup(true))
    } else {
      // const advertiser = yield call(AuthApi.getAdvertiser, action);
      // console.log("in willGetProfileData function advertiser",advertiser)
      // yield put(ProfileActions.didAddAdvertiser(advertiser))
      yield put(UiActions.didSetNoGroup(false))
      yield put(ProfileActions.willGetAdvertiser(''))
      
      yield put(ProfileActions.didRetrieveProfileData(user.attributes))

      // if (_.isEmpty(advertiser)) { /// se l'utente non ha nessun profilo
      //   // console.log("in willGetProfileData function advertiser true", advertiser)
      //   yield put(UiActions.didSetNoProfile(true))
      //   yield put(ProfileActions.didAddAdvertiser({}))
      // } else {
      //   // console.log("in willGetProfileData function advertiser false", advertiser)
      //   yield put(ProfileActions.didAddAdvertiser(advertiser))
      // }
    }
  } catch (error: any) {
    console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

}

function* willAddAdvertiser(action: any) {
  console.log('in willAddAdvertiser saga');
  yield put(UiActions.startActivityRunning("willAddAdvertiser"));

  try {
    const result = yield call(AuthApi.addAdvertiser, action.payload);
    console.log('with user in willAddAdvertiser: ', result)
    yield put(ProfileActions.didAddAdvertiser(result))
    yield put(NotificationActions.willShowNotification({ message: "Modifiche salvate con successo", type: "success" }));
  } catch (error: any) {
    console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UiActions.stopActivityRunning("willAddAdvertiser"));

}

function* willGetAdvertiser(action: any) {
  console.log('in willGetAdvertiser saga');
  try {
    const result = yield call(AuthApi.getAdvertiser, action);
    console.log('with user in getAdvertiser: ', result)
    yield put(ProfileActions.didAddAdvertiser(result))
  } catch (error: any) {
    console.log('Error retriving profile data', error);
  }
}