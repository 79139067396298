import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { Grid } from '@material-ui/core';
import { useStyles, randomColor } from '../Campain.css';
import Icon from '../../IconsComponent';
import { customBillboardIcon } from '../../CustomBillboardIcon';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';

export const TypeFormat = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)

  let size6x3 = 0 as number
  let size4x3 = 0 as number
  let sizeOthers = 0 as number
  let totalMunicipality = [] as any[]
  let totalNormalized = [] as any[]
  let meanVisibilityIndex = [] as any[]

  let billboardsTypes = [] as string[]
  let billboardTypesData = {} as any
  console.log("billboardTypesData", billboardsTypes, billboardTypesData)

  // cerco tutti i media_type presenti in questi impianti
  plantsSelected.map((billboard: any) => {
    if (!billboardsTypes.includes(billboard.media_type)) {
      billboardsTypes.push(billboard.media_type)
      billboardTypesData[billboard.media_type] = 0
    }
  })

  billboardsTypes.forEach((media_type: string) => {
    plantsSelected.map((billboard: any) => {
      if (media_type === billboard.media_type) {
        billboardTypesData[media_type] = billboardTypesData[media_type] + 1
      }
    })
  })

  plantsSelected.map((i: any) => {
    const mappedSize = `${i.base}x${i.height}`
    // sizeArray.push({ size: mappedSize })
    if (mappedSize === "600x300") {
      size6x3 = size6x3 + 1
    }
    else if (mappedSize === "400x300") {
      size4x3 = size4x3 + 1
    } else {
      sizeOthers = sizeOthers + 1
    }
  })

  plantsSelected.map((i: any) => {
    totalMunicipality.push(i.iV_municipality)
  })
  plantsSelected.map((i: any) => {
    totalNormalized.push(i.iV_normalized)
  })
  meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))

  return (
    <Grid style={{ height: '59vh', overflow: 'auto' }} className={`${classes.confirmStepBorders}`}>
      <Grid data-cy="types_and_format" style={{ fontSize: 20 }} className="p-3 mb-2" item xs={12}><b>Formati e Tipologia</b></Grid>

      {billboardsTypes.map((type: string, index: number) => {

        return (
          <Grid key={index} data-cy={`size-${index}`} className="mb-3" container item xs={12}>
            <Grid container item xs={5} alignItems="center" justify="center">
              <CircularProgressbarWithChildren
                className={classes.visibilityProgress}
                styles={buildStyles({
                  //textColor: "red",
                  pathColor: randomColor(),
                  trailColor: "#f8f9fa"
                })}
                value={billboardTypesData[type] / plantsSelected.length * 100}
                strokeWidth={5} >
                <b className={classes.percentageProgress}>{_.round(billboardTypesData[type] / plantsSelected.length * 100)}%</b>
              </CircularProgressbarWithChildren>
            </Grid>
            <Grid container item xs={7} alignItems="center" >
              <span> {customBillboardIcon(type)} <b>{billboardTypesData[type]}</b></span>
              <span className="text-muted ml-1">{type}</span>
            </Grid>
          </Grid>
        )
      })}

      {/* <Grid data-cy="size-1" container item xs={12}>
        <Grid container item xs={6} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#0066ff",
              trailColor: "#f8f9fa"
            })}
            value={size6x3 / plantsSelected.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(size6x3 / plantsSelected.length * 100)}%</b>
          </CircularProgressbarWithChildren>
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="center">
          <span><Icon size={25} icon="impianto" /> <b>{size6x3}</b></span>
          <span className="text-muted ml-1">600x300</span>
        </Grid>
      </Grid>

      <Grid data-cy="size-2" container item xs={12} direction="row" alignItems="center" justify="center">
        <Grid container item xs={6} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#00e68a",
              trailColor: "#f8f9fa"
            })}
            value={size4x3 / plantsSelected.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(size4x3 / plantsSelected.length * 100)}%</b>
          </CircularProgressbarWithChildren>
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="center">
          <span><Icon size={25} icon="impianto" /> <b>{size4x3}</b></span>
          <span className="text-muted ml-1">400x300</span>
        </Grid>
      </Grid>

      <Grid data-cy="size-3" container item xs={12} direction="row" alignItems="center" justify="center">
        <Grid container item xs={6} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#ff9900",
              trailColor: "#f8f9fa"
            })}
            value={sizeOthers / plantsSelected.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(sizeOthers / plantsSelected.length * 100)}%</b>
          </CircularProgressbarWithChildren>
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="center">
          <span><Icon size={25} icon="impianto" /> <b>{sizeOthers}</b></span>
          <span className="text-muted ml-1">Altri</span>
        </Grid>
      </Grid>

      <Grid data-cy="size-3" container item xs={12} direction="row" alignItems="center" justify="center">
        <Grid container item xs={6} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#ff9900",
              trailColor: "#f8f9fa"
            })}
            value={sizeOthers / plantsSelected.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(sizeOthers / plantsSelected.length * 100)}%</b>
          </CircularProgressbarWithChildren>
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="center">
          <span><Icon size={25} icon="impianto" /> <b>{sizeOthers}</b></span>
          <span className="text-muted ml-1">Altri</span>
        </Grid>
      </Grid> */}

    </Grid>
  )

}