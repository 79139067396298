import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button, IconButton, ButtonGroup, Grid, Paper, Tooltip } from '@material-ui/core';
import { useStyles } from './notifications.css';
import { selectors as NotificationSelectors } from '../../store/slices/notification';
import Icon from "../IconsComponent";

interface NotificationTypes {
  type: string;
  visible: boolean;
  message: string;
}

export const NotificationContainer = ({ children, ...rest }: any) => {
  const classes = useStyles()

  const notification: NotificationTypes = useSelector(NotificationSelectors.getNotification)

  // const notification: NotificationTypes = { // per testarla
  //   type: 'success',
  //   visible: true,
  //   message: 'Lore ipsum non mi ricordo la frase intera in latino, ma scrivo molto perchè posso vedere come ci stanno le scritte lunghe',
  // }

  const notificationTypeName =
    notification.type === 'success' && 'SUCCESS!' ||
    notification.type === 'warning' && 'WARNING' ||
    notification.type === 'info' && 'INFORMATION' ||
    notification.type === 'danger' && 'ERROR' ||
    ''

  return (
    <>
      {children}
      <Grid className={`${classes[notification.type]} ${notification.visible ? 'notificationTransition' : ''}`} container alignItems="center" justify="center">
        {notification.visible &&
          <>
            <Grid container item xs={2} alignItems="center" justify="center">
              <Icon className="mr-3" color="white" size={20} icon="favourite" /> {notificationTypeName}
            </Grid>
            <Grid data-ty="notification-message" style={{ textAlign: 'center' }} container item xs={8} alignItems="center" justify="center">
              {notification.message}
            </Grid>
            <Grid item xs={2} />
          </>}
      </Grid>
    </>
  )
}